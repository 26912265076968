"use strict";

import PasswordValidator from "password-validator";
import moment from "moment-timezone";
import i18n from "@/i18n";
import { validate as validateEmail } from "email-validator";
import { isValidPhoneNumber } from "libphonenumber-js";
import { WorkerMissionStatus } from "@/data";
import { IAddress, LabelValue } from "@/types";
import { LocaleMessages } from "vue-i18n";

export function sortWorkers(a: any, b: any) {
    const statusOrder = [
        WorkerMissionStatus.confirmed,
        WorkerMissionStatus.accepted,
        WorkerMissionStatus.proposed,
        WorkerMissionStatus.reproposed,
        WorkerMissionStatus.validated,
        WorkerMissionStatus.presented,
        WorkerMissionStatus.placed,
        WorkerMissionStatus.notconfirmed,
        WorkerMissionStatus.declined,
        WorkerMissionStatus.rejectedByAgency,
        WorkerMissionStatus.rejectedByCompany,
        WorkerMissionStatus.aborted,
    ];
    const aPos = statusOrder.indexOf(a.status);
    const bPos = statusOrder.indexOf(b.status);

    if (aPos !== -1) {
        if (bPos !== -1) {
            return aPos - bPos;
        } else {
            return -1;
        }
    } else {
        if (bPos !== -1) {
            return 1;
        } else {
            if (a.status === WorkerMissionStatus.accepted) {
                if (a.acceptedTimestamp < b.acceptedTimestamp) {
                    return -1;
                }
                if (a.acceptedTimestamp > b.acceptedTimestamp) {
                    return 1;
                }
            }
            return 0;
        }
    }
}

export function getFormattedHour(hour: number, format?: string) {
    if (hour != null) {
        const h = hour.toString();

        if (h.length === 3) {
            return moment(h, "Hmm").format(i18n.t(format || "date.time2"));
        } else if (h.length === 4) {
            return moment(h, "HHmm").format(i18n.t(format || "date.time2"));
        } else if (h.length === 2) {
            return moment("00" + h, "HHmm").format(i18n.t(format || "date.time2"));
        } else if (h.length === 1) {
            return moment("000" + h, "HHmm").format(i18n.t(format || "date.time2"));
        } else {
            return "";
        }
    }

    return "";
}

export const concat = (values: (string | undefined)[], separator = " "): string => values.filter(Boolean).join(separator);

export function formattedAddress(address: IAddress | undefined, options?: { separator?: string; complement?: string }) {
    if (!address) return "";
    const { separator = ", ", complement } = options ?? {};
    const { street, zip, city } = address;
    return concat([street, complement, zip, city], separator);
}

export function passwordStrength(password: string) {
    const schema1 = new PasswordValidator();
    const schema2 = new PasswordValidator();
    const schema3 = new PasswordValidator();
    const schema4 = new PasswordValidator();

    schema1.min(8);
    schema2.uppercase(1);
    schema3.symbols(1);
    schema4.digits(1);

    const valid1 = schema1.validate(password);
    const valid2 = schema2.validate(password);
    const valid3 = schema3.validate(password);
    const valid4 = schema4.validate(password);

    let score: number = 0;

    if (valid1) {
        score += 1;
    }
    if (valid2) {
        score += 1;
    }
    if (valid3) {
        score += 1;
    }
    if (valid4) {
        score += 1;
    }

    return score;
}

export function formatErrors(errors: any, to?: string, fullErr?: any) {
    let formatedErrors: any = { errors: {}, _global: [] };

    if (errors && Array.isArray(errors)) {
        errors.forEach((err: any) => {
            if (err.id) {
                if (err.id === "Validation.EmailIsAlreadyUsed") {
                    err.context = "email";
                }
                if (typeof err.context === "string") {
                    if (err.context.indexOf("body.") !== -1) {
                        err.context = err.context.replace("body.", "");
                    }
                    formatedErrors.errors[err.context] = {};
                    formatedErrors.errors[err.context].id = err.id;
                    formatedErrors.errors[err.context].action = to || "";
                    formatedErrors.errors[err.context].key = err.context;
                    formatedErrors.errors[err.context].data = err.data;
                } else {
                    formatedErrors._global.push({
                        id: err.id,
                        action: to,
                        data: err.data,
                    });
                }
            }
        });
    }

    return {
        errors: formatedErrors.errors || {},
        _global: formatedErrors._global || [],
        fullErr,
    };
}

export function capitalize(string: string | undefined | null) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAllString(string: string | undefined | null) {
    return string && string.toUpperCase();
}

export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function pluralize(string: string | undefined | null, n: number) {
    return string && n > 1 ? `${string}s` : string;
}

export function getNumeric(value: any) {
    if (isNaN(value) || value === null || value === undefined) {
        return "-";
    }
    return parseFloat(value);
}

export function getString(value: any) {
    return (value || "-").toString();
}

export function getDuration(value: any) {
    return moment.duration(value, "millisecond").humanize();
}

export function isValidEmail(email: string) {
    return validateEmail(email);
}

export function isValidPhone(phone: string) {
    return isValidPhoneNumber(phone, "FR");
}

export function toastError(err: any) {
    return "Impossible d'exécuter cette commande pour le moment.";
}

export function isSameRoute(a: any, b: any) {
    const START = "/";
    const trailingSlashRE = /\/?$/;

    function isObjectEqual(a: any, b: any): any {
        if (a === void 0) a = {};
        if (b === void 0) b = {};

        // handle null value #1566
        if (!a || !b) {
            return a === b;
        }
        var aKeys = Object.keys(a);
        var bKeys = Object.keys(b);
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        return aKeys.every(function (key) {
            var aVal = a[key];
            var bVal = b[key];
            // check nested equality
            if (typeof aVal === "object" && typeof bVal === "object") {
                return isObjectEqual(aVal, bVal);
            }
            return String(aVal) === String(bVal);
        });
    }

    if (b === START) {
        return a === b;
    } else if (!b) {
        return false;
    } else if (a.path && b.path) {
        return a.path.replace(trailingSlashRE, "") === b.path.replace(trailingSlashRE, "") && a.hash === b.hash && isObjectEqual(a.query, b.query);
    } else if (a.name && b.name) {
        return a.name === b.name && a.hash === b.hash && isObjectEqual(a.query, b.query) && isObjectEqual(a.params, b.params);
    } else {
        return false;
    }
}

export function NaNOrValue(option: LabelValue) {
    return option && (typeof option.value === "number" ? option.label : option.value);
}

export function NaNOrValueMore(option: LabelValue) {
    return option && (typeof option.value === "number" ? ["label", option.label] : ["value", option.value]);
}

export function mandatoryField(str: string | LocaleMessages, mandatory: boolean) {
    return mandatory ? `${str} *` : str;
}

export type PriceOption = {
    notCents?: boolean;
    thousandSeparator?: string;
    suffix?: string | boolean;
    noFraction?: boolean;
};

export function formatPrice(value: number, options?: PriceOption) {
    let negative = Math.sign(value) == -1;
    let price = options && options.notCents ? Math.abs(Math.round(value * 100)) : Math.abs(Math.round(value));
    let int = Math.floor(price / 100);
    let intStr = "" + int;
    if (options === undefined || options === null || options.thousandSeparator !== "") {
        let separator =
            !options || options.thousandSeparator === null || options.thousandSeparator === undefined ? "&nbsp;" : options.thousandSeparator;
        let formattedInt = "";
        let part;
        for (let i = intStr.length; i > 0; i -= 3) {
            if (i >= 3) {
                part = intStr.substring(i - 3, i);
            } else if (i > 0) {
                part = intStr.substring(0, i);
            } else {
                part = null;
            }

            if (part !== null) {
                if (formattedInt.length > 0) {
                    formattedInt = part + separator + formattedInt;
                } else {
                    formattedInt = part;
                }
            }
        }
        intStr = formattedInt;
    }
    let frac = price % 100;
    let fracStr: string;
    let absFrac = Math.abs(frac);
    if (absFrac < 10) {
        fracStr = "0" + absFrac;
    } else {
        fracStr = absFrac.toString();
    }

    if (isNaN(int) || isNaN(absFrac)) {
        return "-";
    } else {
        let suffixStr;
        if (options && options.suffix) {
            suffixStr = options.suffix;
        } else {
            if (options && options.suffix === false) {
                suffixStr = "";
            } else {
                suffixStr = "&nbsp;€";
            }
        }

        if (options && options.noFraction) {
            fracStr = "";
        } else {
            fracStr = "," + fracStr;
        }

        return (negative ? "-" : "") + intStr + fracStr + suffixStr;
    }
}

export function shortenParagraph(paragraph: string, maxLength: number) {
    if (!paragraph) return "";

    return paragraph.length <= maxLength ? paragraph : paragraph.substr(0, maxLength).trim() + "...";
}

export function debounce(func: any, wait: any, immediate: any): any {
    let timeout: any;

    return function executedFunction(): any {
        // @ts-ignore
        let context: any = this;
        let args = arguments;

        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export const isset = (value: any) => !["", null, undefined].includes(value);

export function omit<Data extends object, Keys extends keyof Data>(data: Data, keys: Keys[]): Omit<Data, Keys> {
    const entries = Object.entries(data).filter(([key]) => !keys.includes(key as Keys));
    return Object.fromEntries(entries) as Omit<Data, Keys>;
}

export function getDurationByUnit(timestamp: number, unit: string = "days"): number {
    const start = moment(timestamp);
    const today = moment();
    return Math.abs(Math.ceil(moment.duration(today.diff(start)).as(unit)));
}

export function calculateDuration(start: string, end: string, unit: string): number {
    const startDate = moment(start);
    const endDate = moment(end);

    const duration = moment.duration(endDate.diff(startDate));
    const durationInUnit = duration.as(unit);

    return Math.ceil(durationInUnit);
}

export const removeAccents = (str: string) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
